<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
          id="printMe"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">Vardiya Gelir Listesi</h4>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-print="'#printMe'"
                  variant="bgInfo"
                  class="myButton border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">Gelir Kayıtlarını Yazdır</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="bgInfo"
                  :to="'/vardiyaOzeti/' + vardiyaID"
                  class="mr-1 myButton border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="GridIcon"
                    class="mr-50"
                />
                <span class="align-middle">Vardiya Özeti</span>
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr>
        <b-row class="mt-lg-2 mt-sm-4 mt-2">
          <b-col
              xl="8"
              lg="12"
              cols="12"
              sm="12"
          >
            <table class="table table-striped table-hover table-responsive-sm"
                   :class="[width < 1000? 'table-responsive':'',width < 580 ? 'mt-2':'']"
            >
              <thead class="text-center">
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  REZ NO
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  FİŞ NO
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  DEPARTMAN
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  KAYIT TÜRÜ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  MÜŞTERİ ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TUTAR
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  AÇIKLAMA
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TARİH
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(gelir, index) in detayliListReturn"
                  :key="index"
                  class="text-center"
              >
                <td>{{ gelir.rezervasyonNo }}</td>
                <td>{{ gelir.fisNo }}</td>
                <td>
                  {{ gelir.departmanAdi }}
                </td>
                <td>
                  {{ gelir.odemeTuru }}
                </td>
                <td>{{ gelir.adi }} {{ gelir.soyAdi }}</td>
                <td>{{ gelir.gelir }} TL</td>
                <td>{{ gelir.aciklama }}</td>
                <td class="nowrap">
                  {{ gelir.kayitTarih }}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col
              xl="3"
              lg="12"
              cols="12"
              sm="12"
              class="mt-1"
          >
            <div class="verticalHr"/>
            <div
                v-for="(departman, index) in this.$store.state.raporlar
                .gelirKayitlari.departmanOzeti"
                :key="index"
            >
              <h6>
                {{ departman.departmanAdi }} :
                {{ departman.toplamGelir }}
              </h6>
            </div>
            <hr>
            <p>
              Nakit:
              {{
                $store.getters.detayliGelirKayitlariNakitCiro
              }}
              TL
            </p>
            <p>
              Kredi Kartı :
              {{
                $store.getters.detayliGelirKayitlariKKrediKartiCiro
              }}
              TL
            </p>
            <p>
              Havale :
              {{
                $store.getters.detayliGelirKayitlariHavaleCiro
              }}
              TL
            </p>
            <p>
              Cari Kredi :
              {{
                $store.getters.detayliGelirKayitlariKrediCiro
              }}
              TL
            </p>
            <p>
              Online Ciro :
              {{
                $store.state.raporlar.gelirKayitlari.onlineCiro == null
                    ? 0
                    : $store.state.raporlar.gelirKayitlari.onlineCiro
              }}
              TL
            </p>
            <small>*Toplam tutara yansımaz</small>
            <hr>
            <h3>
              Toplam Gelir :
              {{
                $store.getters.detayliGelirKayitlarToplamCiro
              }}
              TL
            </h3>
          </b-col>
          <b-col cols="1"/>
        </b-row>
        <hr>
        <h4 class="mt-2">
          Vardiya Tahsilat Listesi
        </h4>
        <b-row class="mt-2">
          <b-col lg="9" cols="12">
            <table class="table table-striped">
              <thead class="text-center">
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  ÜNVAN
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  İSİM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  ÖDEME TÜRÜ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  KULLANICI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TUTAR
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  AÇIKLAMA
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TARİH
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(tah, index) in this.$store.getters
                    .detayliGelirKayitlarTahsilatListesi"
                  :key="index"
                  class="text-center"
              >
                <td>{{ tah.unvan }}</td>
                <td>{{ tah.isim }}</td>
                <td>{{ tah.tahtur }}</td>
                <td>{{ tah.userName }}</td>
                <td>{{ tah.tutar }}</td>
                <td>{{ tah.aciklama }}</td>
                <td>{{ tah.kayitTarih }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="3" cols="12">
            <hr/>
            <p>
              Nakit Tahsilat :
              {{
                $store.getters.detayliGelirKayitlarTahsilatNakitMoney
              }}
              TL
            </p>
            <p>
              Kredi Kartı Tahsilat :
              {{
                $store.getters.detayliGelirKayitlarTahsilatKrediKartiMoney
              }}
              TL
            </p>
            <p>
              Havale Tahsilat :
              {{
                $store.getters.detayliGelirKayitlarTahsilatTahsilatMoney
              }}
              TL
            </p>
            <hr>
            <h3>
              Toplam Tahsilat :
              {{
                $store.getters.detayliGelirKayitlarTahsilatDefaultTah
              }}
              TL
            </h3>
          </b-col>
        </b-row>
        <hr>
        <h4 class="mt-2">
          Vardiya Gider Listesi
        </h4>
        <b-row class="mt-2">
          <b-col lg="9" cols="12">
            <table class="table table-striped table-responsive-sm">
              <thead class="text-center">
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TUTAR
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  AÇIKLAMASI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TARİH
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(gider, index) in this.$store.getters
                    .detayliGelirKayitlarInsideGider"
                  :key="index"
                  class="text-center"
              >
                <td>{{ gider.tutar }} TL</td>
                <td>{{ gider.aciklama }}</td>
                <td>{{ gider.kayitTarih }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="3" md="6" sm="3" cols="12">
            <hr/>
            <h3>
              Toplam Gider :
              {{
                $store.getters.detayliGelirKayitlarInsideGiderler
              }}
              TL
            </h3>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-start">
          <h4 class="ml-1">
            Vardiya Kasa Durumu
          </h4>
          <small class="ml-1">* Tahsilat ve Giderler Sonrası Kasa</small>
        </b-row>
        <p>
          Toplam Nakit :
          {{ $store.getters.detayliGelirKayitlarNakitCiro }} TL
        </p>
        <p>
          Toplam Kredi Kartı :
          {{ $store.getters.detayliGelirKayitlarKkartiCiro }} TL
        </p>
        <p>
          Toplam Havale :
          {{ $store.getters.detayliGelirKayitlarHavaleCiro }} TL
        </p>
        <hr>
        <h3>
          <b>Genel Toplam :
            {{
              $store.getters.detayliGelirKayitlarTotalCiro
            }}
            TL</b>
        </h3>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BContainer,
  BCol,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      vardiyaID: this.$route.params.id,
    }
  },
  computed: {
    detayliListReturn() {
      if (this.$store.getters.detayliGelirKayitlariReturn) {
        return _.orderBy(
            this.$store.getters.detayliGelirKayitlariReturn,
            'rezervasyonNo',
            'asc',
        )
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('clearGelirKayitlari')
  },
  created() {
    if (this.vardiyaID != 0) {
      this.$store
          .dispatch('gelirKayitlari', {
            vardiyaID: this.vardiyaID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    } else {
      this.spinner = false
      this.$store
          .dispatch('gelirKayitlari', {
            vardiyaID: 0,
          })
          .then(res => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    }
  },
  methods: {
    print() {
      window.print()
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>
<style scoped>
[dir] .table thead th {
  border-bottom: 2px solid #ebe9f1;
  padding: 3px !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.v-select {
  display: inline-block !important;
  width: 400px;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header h4 {
  position: absolute;
  color: black;
  margin-top: 20px;
}

.row {
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap !important;
}

thead tr {
  white-space: nowrap !important;
  text-align: center !important;
  background-color: #f3f2f7 !important;
}

.verticalHr {
  background-color: #ebe9f1;
  height: 100%;
  width: 1px;
  position: absolute;
  transform: translateX(-12px);
}
</style>
<style>
.content-wrapper {

}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white !important;
  padding: 0;
  margin: 0;
}
</style>
